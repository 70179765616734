export const EN_CAMPAIGN_STRINGS = {
  CAMPAIGNS: '{count, plural, one {Campaign} other {Campaigns}}',
  CAMPAIGN_ENTITY_NAME: 'Campaign',
  CAMPAIGN_METADATA_LABEL: 'Metadata',
  CAMPAIGN_METADATA_INTERNAL_NAME_LABEL: 'Internal Name',
  CAMPAIGN_METADATA_INTERNAL_NAME_HINT:
    'This is used to refer to the Campaign internally. It will not be seen by the user',
  CAMPAIGN_METADATA_EXTERNAL_ID_LABEL: 'External ID',
  CAMPAIGN_METADATA_EXTERNAL_ID_HINT:
    'This is used to refer to the Campaign internally. It will not be seen by the user',
  CAMPAIGN_METADATA_TYPE_LABEL: 'Type',
  CAMPAIGN_DATE_LABEL: 'Campaign Duration',
  CAMPAIGN_DATE_START_DATE_LABEL: 'Start Date',
  CAMPAIGN_DATE_END_DATE_LABEL: 'End Date',
  CAMPAIGN_DATE_VISIBILITY_END_DATE_HINT:
    'This determines when to stop showing the campaign, even after it has ended',
  CAMPAIGN_DATE_VISIBILITY_END_DATE_LABEL: 'Visibility End Date',
  CAMPAIGN_CONTENT_LABEL: 'Content',
  CAMPAIGN_CONTENT_TITLE_LABEL: 'Title',
  CAMPAIGN_CONTENT_TITLE_HINT: 'Title seen by the user',
  CAMPAIGN_CONTENT_PROGRAM_HEADER_LABEL: 'Program Header',
  CAMPAIGN_CONTENT_PROGRAM_HEADER_HINT: 'Displays a badge on the Campaign Card',
  CAMPAIGN_CONTENT_SHORT_DESCRIPTION_LABEL: 'Short Description',
  CAMPAIGN_CONTENT_SHORT_DESCRIPTION_HINT: 'Displays on Cards',
  CAMPAIGN_CONTENT_LONG_DESCRIPTION_LABEL: 'Long Description',
  CAMPAIGN_CONTENT_OVERVIEW_LABEL: 'Overview',
  CAMPAIGN_CONTENT_OVERVIEW_HINT: 'Displays on the detail page of the Campaign',
  CAMPAIGN_CONTENT_CAMPAIGN_IMAGE_ID_LABEL: 'Campaign Image ID',
  CAMPAIGN_CONTENT_CAMPAIGN_IMAGE_ID_HINT:
    'Shown in cards and in the header of the Campaign',
  CAMPAIGN_CONTENT_ACHIEVEMENT_LABEL: 'Achievement',
  CAMPAIGN_CONTENT_ACHIEVEMENT_HINT:
    'If the user gains an achievement for completing this campaign, these fields determine how it appears',
  CAMPAIGN_CONTENT_ACHIEVEMENT_TITLE_LABEL: 'Achievement Title',
  CAMPAIGN_CONTENT_ACHIEVEMENT_TITLE_HINT: '',
  CAMPAIGN_CONTENT_ACHIEVEMENT_TEXT_LABEL: 'Achievement Text',
  CAMPAIGN_CONTENT_ACHIEVEMENT_TEXT_HINT: '',
  CAMPAIGN_CONTENT_ACHIEVEMENT_IMAGE_LABEL: 'Achievement Image',
  CAMPAIGN_CONTENT_ACHIEVEMENT_GLOBAL_PROGRESS_LABEL: 'Display Global Progress',
  CAMPAIGN_CONTENT_ACHIEVEMENT_GLOBAL_PROGRESS_HINT:
    'Determines if a progress bar is displayed in the Campaign Listing',
  CAMPAIGN_CONTENT_DISCLAIMER_BANNER: 'Disclaimer Banner',
  CAMPAIGN_CONTENT_DISCLAIMER_BANNER_TITLE_LABEL: 'Title',
  CAMPAIGN_CONTENT_DISCLAIMER_BANNER_DESCRIPTION_LABEL: 'Description',
  CAMPAIGN_CONTENT_DISCLAIMER_BANNER_ICON_IMAGE_ID_LABEL: 'Icon Image',
  CAMPAIGN_CONTENT_PROGRAM_RULES_LABEL: 'Program Rules Text',
  CAMPAIGN_CONTENT_CONTENT_PROVIDER_LABEL: 'Content Provider',
  CAMPAIGN_CONTENT_CONTENT_PROVIDER_HINT:
    'If this campaign is provided by a third-party, enter their details here',
  CAMPAIGN_CONTENT_CONTENT_PROVIDER_NAME_LABEL: 'Name',
  CAMPAIGN_CONTENT_CONTENT_PROVIDER_DESCRIPTION_LABEL: 'Description',
  CAMPAIGN_CONTENT_CONTENT_PROVIDER_LOGOTYPE_IMAGE_ID_LABEL: 'Logotype Image',
  CAMPAIGN_CONTENT_CONTENT_PROVIDER_LOGOMARK_IMAGE_ID_LABEL: 'Logomark Image',
  CAMPAIGN_ENROLMENT_LABEL: 'Enrollment',
  CAMPAIGN_ENROLMENT_ELIGIBILITY_CRITERIA_LABEL: 'Eligibility Criteria',
  CAMPAIGN_ENROLMENT_ELIGIBILITY_CRITERIA_HINT:
    'This Campaign will not display to users who are ineligible',
  CAMPAIGN_ENROLMENT_ENROLMENT_METHOD_LABEL: 'Enrollment Method',
  CAMPAIGN_ENROLMENT_ENROLMENT_DURATION_LABEL: 'Enrollment Duration',
  CAMPAIGN_ENROLMENT_ENROLMENT_DURATION_START_DATE_LABEL: 'Start Date',
  CAMPAIGN_ENROLMENT_ENROLMENT_DURATION_END_DATE_LABEL: 'End Date',
  CAMPAIGN_ENROLMENT_FRONT_LINE_CAMPAIGN_LABEL: 'Front of the Line Campaign',
  CAMPAIGN_ENROLMENT_FRONT_LINE_CAMPAIGN_HINT:
    'Determines if this Campaign will take priority over other recommended campaigns',
  CAMPAIGN_ENROLMENT_ROOT_CAMPAIGN_LABEL: 'Is Root Campaign',
  CAMPAIGN_ENROLMENT_CHILD_CAMPAIGNS_LABEL: 'Child Campaigns',
  CAMPAIGN_ENROLMENT_CHILD_CAMPAIGNS_HINT:
    'If this Campaign has nested Campaigns, identify them here',
  CAMPAIGN_ENROLMENT_CHILD_CAMPAIGNS_ADD_LABEL: 'Add Child Campaign',
  CAMPAIGN_COMPLETION_LABEL: 'Completion',
  CAMPAIGN_COMPLETION_COMPLETION_CRITERIA_LABEL: 'Completion Criteria',
  CAMPAIGN_COMPLETION_COMPLETION_CRITERIA_HINT:
    'Determines when the Campaign is marked as completed',
  CAMPAIGN_COMPLETION_MAX_REDEMPTION_POINTS_HINT:
    'Determines the maximum number of points that will be rewarded for all child Campaigns and Activities',
  CAMPAIGN_COMPLETION_MAX_REDEMPTION_POINTS_LABEL: 'Max Redemption Points',
  CAMPAIGN_COMPLETION_REWARD_PROGRAM_GOAL_HINT:
    'If this campaign is eligible for rewards programs, specify the number of activities in the campaign that a user must complete in order to earn reward points',
  CAMPAIGN_COMPLETION_REWARD_PROGRAM_GOAL_LABEL: 'Reward Program Goal',
  CAMPAIGN_COMPLETION_PROGRAM_COMPLETION_TITLE_LABEL:
    'Program Completion Title',
  CAMPAIGN_COMPLETION_PROGRAM_COMPLETION_TITLE_HINT:
    'Title to display on card when the Campaign is completed',
  CAMPAIGN_COMPLETION_COMPLETION_MESSAGE_LABEL: 'Completion Message',
  CAMPAIGN_COMPLETION_COMPLETION_MESSAGE_HINT:
    'Message to display on card when the Campaign is completed',
  CAMPAIGN_COMPLETION_PROGRAM_ENDED_TITLE_LABEL: 'Program Ended Title',
  CAMPAIGN_COMPLETION_PROGRAM_ENDED_TITLE_HINT:
    'Title to display on card when the Campaign has ended',
  CAMPAIGN_COMPLETION_ENDED_MESSAGE_LABEL: 'Ended Message',
  CAMPAIGN_COMPLETION_ENDED_MESSAGE_HINT:
    'Message to display on card when the Campaign has ended',
  CAMPAIGN_CAMPAIGN_NODES_LABEL: 'Campaign Nodes',
  CAMPAIGN_CAMPAIGN_NODE_LABEL: 'Campaign Node',
  CAMPAIGN_CAMPAIGN_NODES_NODE_NAME_LABEL: 'Node name',
  CAMPAIGN_CAMPAIGN_NODES_OFFSET_LABEL: 'Offset',
  CAMPAIGN_CAMPAIGN_NODES_ACTIVITIES_LABEL: 'Activities',
  CAMPAIGN_CAMPAIGN_NODES_ACTIVITIES_ACTIVITY_ID_LABEL: 'Activity',
  CAMPAIGN_CAMPAIGN_NODES_ACTIVITIES_CAN_EXPIRE_LABEL: 'Can Expire',
  CAMPAIGN_CAMPAIGN_NODES_ACTIVITIES_EXPIRE_IN_LABEL: 'Expire In',
  CAMPAIGN_CAMPAIGN_NODES_ACTIVITIES_EXPIRE_IN_HINT:
    'Any integer from 1 to 365 is allowed. 1 means in 1 Day',
  /* Rules template names */
  COMPLETE_CAMPAIGN_MEET_REWARD_POINT_THRESHOLD:
    'Complete Campaign based on meeting Reward point threshold',
  CAMPAIGN_PROGRAM_WITH_ONE_RULE_CONDITION: 'Program with 1 rule condition',
  CAMPAIGN_PROGRAM_WITH_NOT_CONDITION: 'Program with not condition',
  CAMPAIGN_PROGRAM_WITH_ALL_MATCH: 'Program with all match',
  CAMPAIGN_PROGRAM_WITH_ANY_MATCH: 'Program with any match',
  CAMPAIGN_PROGRAM_WITH_MULTIPLE_RULE_CONDITIONS:
    'Program with multiple rule conditions',
  CAMPAIGN_PROGRAM_WITH_ANY_MATCH_AND_JOINED_BEFORE_DAYS:
    'Program with any match and joined before days',
  CAMPAIGN_ELIGIBILITY_SEGMENT_EVEN_AND_ODD_AGE: 'Segment by Even and Odd Age',
  CAMPAIGN_ELIGIBILITY_COMPLETED_CAMPAIGN_BEFORE_DATE:
    'Check if the user completed a campaign before a certain date',
  CAMPAIGN_ENROLLABLE_LABEL: 'Enrollable',
  CAMPAIGN_ENROLLABLE_HINT:
    'Set this to false if you do not want new users to be able to enroll in this campaign.',
  CAMPAIGN_IMPACTED_MEMBERS_LABEL: 'Impacted members',
  CAMPAIGN_IMPACTED_MEMBERS_HINT:
    'Who do you want to publish these updates to?',
  CAMPAIGN_IMPACTED_MEMBERS_NEW: 'New enrollments only',
  CAMPAIGN_IMPACTED_MEMBERS_CURRENT: 'Current and new enrollments',
  CAMPAIGN_ENROLMENT_ENROLMENT_METHOD_PLACEHOLDER:
    'Choose an enrollment method',
  CAMPAIGN_ENROLMENT_ENROLMENT_METHOD_OPT_IN: 'Opt in',
  CAMPAIGN_ENROLMENT_ENROLMENT_METHOD_AUTO_ENROLLED: 'Auto enrolled',
  CAMPAIGN_ENROLMENT_ENROLMENT_METHOD_CARE_TEAM_MEMBER: 'Assigned',
  CAMPAIGN_NODE_PROPERTIES: 'Campaign Node Properties',
  ACTION_PROPERTIES: 'Action Properties',
  ACTION_NODE: 'Action Node',
  ACTION_TYPE: 'Action Type',
  SELECT_AN_ACTION: 'Select an Action',
  NODE_ID: 'Node ID',
  NODE_LABEL: 'Node Name',
  CAMPAIGN_NODES_TRANSITIONS_LABEL: 'Next Nodes',
  CAMPAIGN_NODE: 'Node',
  CAMPAIGN_FLOW: 'Campaign Flow',
  CAMPAIGN_NODES_LABEL_ADD: 'Add a Campaign Node',
  CAMPAIGN_NODES_NEXT_NODE_REF_LABEL: 'Next Node ID',
  CAMPAIGN_NODES_DECISION_RESULT_LABEL: 'Segmentation Rule Result',
  CAMPAIGN_NODES_RESULT_TRUE_FALSE:
    'This is the result of the evaluation of the rule above. It is either true or false.',
  IF_TRUE: 'If true',
  IF_FALSE: 'If false',
  CAMPAIGN_NODES_SELECT_DECISION_RULE_RESULT: 'Select a rule result',
  CAMPAIGN_NODES_ACTIVITY_SETS: 'Activity Sets',
  CAMPAIGN_NODES_ACTIVITY_SET: 'Activity Set',
  CAMPAIGN_NODES_ADD_ACTIVITY_SET: 'Add an Activity Set',
  CAMPAIGN_NODES_SET_SIZE: 'Number of activities chosen',
  CAMPAIGN_NODES_SET_SIZE_HINT:
    'This is the number of activities that will be selected by the system from the set below, based on eligibility. If left blank, as many as possible from the list will be selected.',
  CAMPAIGN_NODES_ACTIVITY_SET_NAME: 'Activity Set Name',
  CAMPAIGN_NODES_DECISION_NODE: 'Decision Node',
  CAMPAIGN_NODES_SEGMENT_NODE: 'Campaign Segment Node',
  CAMPAIGN_NODES_SEGMENTATION_RULE: 'Segmentation Rule',
  CAMPAIGN_NODES: 'Campaign Nodes',
  NODE_NAME: 'Node Name',
  CAMPAIGN_CONTENT_CAMPAIGN_DATA_COLLECTION_LABEL: 'Data Collection',
  CAMPAIGN_CONTENT_CAMPAIGN_DATA_COLLECTION_HINT:
    'If this campaign has Activities that use data from wearables or other sources to track completion, setting these attributes will let the user know that this is the case.',
  CAMPAIGN_CONTENT_CAMPAIGN_DATA_FIELD_LABEL: 'Data Tracked',
  CAMPAIGN_CONTENT_CAMPAIGN_DATA_FIELD_STEPS: 'Steps',
  CAMPAIGN_CONTENT_CAMPAIGN_DATA_FIELD_ACTIVE_DURATION: 'Active Duration',
  CAMPAIGN_CONTENT_CAMPAIGN_DATA_FIELD_MINDFUL_DURATION: 'Mindful Duration',
  CAMPAIGN_CONTENT_CAMPAIGN_DATA_FIELD_SLEEP_DURATION: 'Sleep Duration',
  CAMPAIGN_CONTENT_CAMPAIGN_DATA_FIELD_FLOORS_CLIMBED: 'Floors Climbed',
  CAMPAIGN_CONTENT_CAMPAIGN_DATA_FIELD_DISTANCE: 'Distance Traveled',
  CAMPAIGN_CONTENT_CAMPAIGN_DATA_FIELD_ENERGY_BURNED: 'Energy Burned',
  CAMPAIGN_CONTENT_CAMPAIGN_DATA_CALLOUT_CTA_TEXT_LABEL: 'Callout CTA Text',
  CAMPAIGN_CONTENT_CAMPAIGN_DATA_CALLOUT_CTA_TEXT_HINT:
    'If you need to link out to another page (such as a consent form), this is what the button will display to the user.',
  CAMPAIGN_CONTENT_CAMPAIGN_DATA_CALLOUT_CTA_URL_LABEL: 'Callout CTA URL',
  CAMPAIGN_CONTENT_CAMPAIGN_DATA_CALLOUT_CTA_URL_HINT:
    'The URL that the above button will link out to.',
  NODE_TYPE: 'Node type',
};
