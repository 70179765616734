import * as React from 'react';
import {
  Box,
  Icon,
  ParagraphText,
  StackLayout,
  StatusBanner,
  TextAction,
  keyframes,
} from '@leagueplatform/genesis-core';
import { Link } from '@leagueplatform/routing';

type ErrorLink = {
  detail: string;
  path?: string;
};

type ErrorBannerProps = {
  title: string;
} & (
  | { errorMessage: string; errorList?: never }
  | { errorMessage?: never; errorList: ErrorLink[] }
);

const slideInFromTop = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-100%)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const ErrorItem: React.FC<ErrorLink> = ({ detail, path }) => (
  <StackLayout orientation="horizontal" verticalAlignment="center">
    {path ? (
      <>
        <TextAction
          as={Link}
          to={`?path=${path}`}
          css={{ typography: '$bodyOne', color: '$onSurfaceTextPrimary' }}
        >
          {detail}
        </TextAction>
        <Icon
          icon="interfaceArrowRight"
          size="$threeQuarters"
          tint="$interactiveActionPrimary"
          css={{ marginLeft: '$half' }}
        />
      </>
    ) : (
      detail
    )}
  </StackLayout>
);

export const ErrorBanner: React.FC<ErrorBannerProps> = ({
  title,
  errorMessage,
  errorList,
}) => (
  <StatusBanner
    status="error"
    data-testid="config-error-banner"
    title={title}
    css={{
      animation: `${slideInFromTop} $transitions$defaultTime ease-in`,
      transformOrigin: 'top',
    }}
  >
    {errorMessage && <ParagraphText>{errorMessage}</ParagraphText>}
    {errorList && errorList.length > 0 ? (
      <Box
        as="ul"
        css={{
          'li::marker': {
            fontSize: '1rem',
          },
          listStyleType: 'revert',
          marginLeft: '$one',
          marginTop: '$quarter',
          typography: '$bodyOne',
          color: '$onSurfaceTextPrimary',
        }}
      >
        {errorList.map((error, index) => {
          const key = `${error.path}-${index}`;
          return (
            <li key={key}>
              <ErrorItem detail={error.detail} path={error.path} />
            </li>
          );
        })}
      </Box>
    ) : null}
  </StatusBanner>
);
