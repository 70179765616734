import type { SchemaTransformerArgs } from '@web-config-app/core';
import { mapEntityReferenceAnnotationToSchemaProperty } from '../../map-entity-reference-annotation-to-schema-property/map-entity-reference-annotation-to-schema-property';

export const injectEntityReferenceAnnotation = ({
  schema,
  options,
}: SchemaTransformerArgs) =>
  schema.type === 'object' &&
  Boolean(schema['x-entity-inject-reference']) &&
  options?.relationshipsSchema
    ? mapEntityReferenceAnnotationToSchemaProperty(
        schema,
        options.relationshipsSchema,
      )
    : schema;
