import * as React from 'react';
import { JsonFormsDispatch } from '@jsonforms/react';
import { StackLayout, Box, VisuallyHidden } from '@leagueplatform/genesis-core';
import { Generate, composePaths } from '@jsonforms/core';
import { Accordion } from '@leagueplatform/web-common-components';
import type { AnnotatedJsonSchema } from '@web-config-app/core';
import type {
  ArrayControl,
  EntityFormArrayControlProps,
  PrimitiveObjectArrayControlItem,
} from '../../../types/controls';
import { createDefaultValueForSchema } from '../../../utils/create-default-value-for-schema/create-default-value-for-schema';
import { EntityFormArrayControl } from '../../entity-form-array-control/entity-form-array-control.component';
import { ArrayAddButton } from '../../array-add-button/array-add-button.component';
import { CombinatorArrayAddActionMenu } from '../../combinator-array-add-action-menu/combinator-array-add-action-menu.component';
import { CompoundPrimitiveGroupFieldset } from '../../compound-primitive-group-fieldset/compound-primitive-group-fieldset.component';
import { addCombinatorOptionToArray } from '../../../utils/add-combinator-option-to-array/add-combinator-option-to-array.util';
import { getDataPointValue } from '../../../utils/get-data-point-value/get-data-point-value';
import { useArrayControlCombinatorHelpers } from '../../../hooks/use-array-control-combinator-helpers/use-array-control-combinator-helpers.hook';
import { EmptyArrayState } from '../../empty-array-state/empty-array-state.component';
import { PrimitiveObjectArrayItem } from './primitive-object-array-item.component';
import { PrimitiveObjectArrayItemWithEntityReference } from './primitive-object-array-item-with-entity-reference.component';

/**
 * This control renders for Arrays of Object Primitive type - excluding primitive arrays
 * On move / up / down and delete all accordions are closed
 *
 * Creates a uiSchema for children of type 'PrimitiveObjectArrayItem' so that the object is rendered with correct ObjectPrimitiveArrayItemLayout
 *
 */

const getEntityReferencePropertySchema = (
  schema: AnnotatedJsonSchema | undefined,
  data: any,
) => {
  const entityReferencePropertyEntries =
    schema &&
    Object.entries(schema.properties ?? {}).filter(
      ([, propertySchema]: [string, AnnotatedJsonSchema]) =>
        Boolean(propertySchema['x-entity-reference']?.relationship),
    );
  if (
    !entityReferencePropertyEntries ||
    entityReferencePropertyEntries.length !== 1
  )
    return null;
  const [dataIdProperty, entityReferencePropertySchema] =
    entityReferencePropertyEntries[0];
  const referenceInstanceId = dataIdProperty && data?.[dataIdProperty];
  return { referenceInstanceId, schema: entityReferencePropertySchema };
};

export const ArrayOfPrimitiveObjectsControlContents = ({
  path,
  schema,
  rootSchema,
  data,
  enabled,
  cells,
  renderers,
  uischema,
  id,
  banner,
  hint,
  label,
  addItem,
  removeItems,
  moveDown,
  moveUp,
  arrayAddLabel,
  itemLabel: defaultItemLabel,
  itemLabelPropertyRef,
  visuallyHiddenItemLabel,
  errors,
  combinatorProperties,
}: EntityFormArrayControlProps) => {
  /**
   * Creates a uiSchema type so that the object is rendered with correct ObjectPrimitiveArrayItemLayout
   */
  const childUiSchema = React.useMemo(
    () =>
      Generate.uiSchema(
        schema,
        'PrimitiveObjectArrayItem',
        undefined,
        rootSchema,
      ),
    [rootSchema, schema],
  );

  const [itemsOpen, setItemsOpen] = React.useState<string[]>([]);

  const handleAccordionValueChange = (newValues: string[]) => {
    setItemsOpen(newValues);
  };

  /**
   * Function to close all accordion items anytime the data length or order changes
   */
  const closeAllAccordionItems = React.useCallback(() => {
    setItemsOpen([]);
  }, []);

  const handleRemoveItem = React.useCallback(
    (itemPath: string, index: any) => () => {
      closeAllAccordionItems();
      removeItems(itemPath, [index])();
    },
    [closeAllAccordionItems, removeItems],
  );

  const handleOnMoveUp = React.useCallback(
    (itemPath: string, index: number) => () => {
      closeAllAccordionItems();
      moveUp(itemPath, index)();
    },
    [closeAllAccordionItems, moveUp],
  );

  const handleOnMoveDown = React.useCallback(
    (itemPath: string, index: number) => () => {
      closeAllAccordionItems();
      moveDown(itemPath, index)();
    },
    [closeAllAccordionItems, moveDown],
  );

  const handleCreateDefaultValue = React.useCallback(
    () => createDefaultValueForSchema(schema),
    [schema],
  );

  const {
    combinatorActionMenuItems,
    getSelectedSubSchema,
    getLabelForSelectedSubSchema,
  } = useArrayControlCombinatorHelpers(combinatorProperties);

  const itemsWithLabels: PrimitiveObjectArrayControlItem[] =
    React.useMemo(() => {
      const items = Array.isArray(data) ? data : [];
      return items.map((item: any, index: number) => {
        const itemNumber = index + 1;
        const isLastItem = items.length === itemNumber;
        const uniqueId = `${id}-${index}`;
        const selectedSubSchema = getSelectedSubSchema(item);
        const subSchemaLabel = getLabelForSelectedSubSchema(selectedSubSchema);
        const itemLabelDataValue = itemLabelPropertyRef
          ? getDataPointValue(item, itemLabelPropertyRef)
          : null;
        const itemLabelAsString = itemLabelDataValue
          ? `${itemLabelDataValue}`
          : '';

        const computedLabel =
          itemLabelAsString?.length > 0 ? itemLabelAsString : subSchemaLabel;
        const childPath = composePaths(path, `${index}`);

        return {
          itemLabel: computedLabel ?? defaultItemLabel,
          isLastItem,
          itemNumber,
          uniqueId,
          childPath,
          schema: selectedSubSchema ?? schema,
          uischema: childUiSchema ?? uischema,
          ...item,
        };
      });
    }, [
      data,
      defaultItemLabel,
      id,
      getSelectedSubSchema,
      getLabelForSelectedSubSchema,
      path,
      schema,
      uischema,
      childUiSchema,
      itemLabelPropertyRef,
    ]);

  const arrayItemsEmpty = itemsWithLabels?.length === 0;

  return (
    /**
     * Todo:: A11y audit of this: https://everlong.atlassian.net/browse/CACT-807
     * i'm not sure if this should be a fieldset or not or if the ol should be labelled as well as the li - but they do not seem to be read out by screen readers - i also do not know if i need that visually hidden legend label or if the buttons to open accordions should have the number in them. I have made it so that the screen-reader behavior makes sense to me, but an audit is needed because semantically right now a lot is happening
     *
     */
    <CompoundPrimitiveGroupFieldset
      label={label}
      banner={banner}
      hint={hint}
      error={errors}
    >
      {arrayItemsEmpty ? (
        <EmptyArrayState item={label} />
      ) : (
        <Accordion.Root
          type="multiple"
          value={itemsOpen}
          onValueChange={handleAccordionValueChange}
        >
          <StackLayout
            as="ol"
            aria-label={label}
            css={{
              marginTop: '$threeQuarters',
              border: '$borderWidths$thin solid $onSurfaceBorderSubdued',
              borderRadius: '$medium',
              paddingLeft: '$none',
              // below overrides default styling
              listStyleType: 'none',
            }}
          >
            {itemsWithLabels.map(
              (
                {
                  uniqueId,
                  schema: itemSchema,
                  uischema: itemUiSchema,
                  isLastItem,
                  itemLabel,
                  itemNumber,
                  childPath,
                }: PrimitiveObjectArrayControlItem,
                index: number,
              ) => {
                const referenceData = getEntityReferencePropertySchema(
                  itemSchema,
                  data[index],
                );
                const {
                  referenceInstanceId,
                  schema: entityReferencePropertySchema,
                } = referenceData ?? {};

                const arrayItemProps = {
                  uniqueId,
                  itemLabel,
                  itemNumber,
                  path,
                  handleOnMoveDown,
                  handleOnMoveUp,
                  handleRemoveItem,
                  index,
                  entityReferencePropertySchema,
                  // this instanceId is consumed by the PrimitiveObjectArrayItemWithEntityReference
                  // in the case where the primitive object contains a field that is an entity reference
                  // ex: activity.id
                  referenceInstanceId,
                  key: uniqueId,
                  arrayLength: itemsWithLabels.length,
                };

                const ArrayItemComponent = entityReferencePropertySchema
                  ? PrimitiveObjectArrayItemWithEntityReference
                  : PrimitiveObjectArrayItem;
                return (
                  <Box
                    key={uniqueId}
                    as="li"
                    css={{
                      width: '100%',
                      padding: '$half $half $half 0',
                      marginTop: 0,
                      borderBottom: `${
                        isLastItem
                          ? 'none'
                          : '$borderWidths$thin solid $onSurfaceBorderSubdued'
                      }`,
                      '&:first-of-type:last-of-type': {
                        borderRadius: '$medium',
                      },
                      '&:first-of-type': {
                        borderRadius: '$medium $medium 0 0',
                      },
                      '&:last-of-type': {
                        borderRadius: '0 0 $medium $medium',
                      },
                      backgroundColor: `${
                        itemsOpen.includes(uniqueId)
                          ? '$surfaceBackgroundSecondary'
                          : '$surfaceBackgroundPrimary'
                      }`,
                    }}
                    aria-label={itemLabel}
                  >
                    <Box as={ArrayItemComponent} {...arrayItemProps}>
                      <Box
                        as="fieldset"
                        css={{
                          paddingX: '$two',
                          paddingTop: '$two',
                          border: '$none',
                        }}
                      >
                        {/* Todo:: A11y audit to see if we need this:
                      https://everlong.atlassian.net/browse/CACT-807 */}
                        <VisuallyHidden as="legend">
                          {visuallyHiddenItemLabel} {itemNumber}
                        </VisuallyHidden>
                        <JsonFormsDispatch
                          enabled={enabled}
                          schema={itemSchema}
                          uischema={itemUiSchema}
                          path={childPath}
                          key={childPath}
                          renderers={renderers}
                          cells={cells}
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              },
            )}
          </StackLayout>
        </Accordion.Root>
      )}
      {combinatorActionMenuItems?.length ? (
        <CombinatorArrayAddActionMenu
          buttonLabel={arrayAddLabel}
          menuItems={combinatorActionMenuItems}
          onSelectItem={(itemId) =>
            addCombinatorOptionToArray({
              value: itemId,
              combinatorProperties,
              path,
              addItem,
            })
          }
        />
      ) : (
        <ArrayAddButton
          addItem={addItem}
          path={path}
          createDefaultValue={handleCreateDefaultValue}
          addButtonLabel={arrayAddLabel}
        />
      )}
    </CompoundPrimitiveGroupFieldset>
  );
};

const renderArrayControl = (formContentsProps: EntityFormArrayControlProps) => (
  <ArrayOfPrimitiveObjectsControlContents {...formContentsProps} />
);

export const ArrayOfPrimitiveObjectsControl: ArrayControl = (props) => (
  <EntityFormArrayControl {...props} renderControl={renderArrayControl} />
);
