import { ConfigApiError, mapStatusToErrorType } from '@web-config-app/core';
import { useConfigUserFeedback } from '../../state/config-user-feedback-state/config-user-feedback-state';

/**
 * Provides an error handler function for entity operations.
 *
 * This hook encapsulates the logic for processing errors received from API calls
 * during entity operations, formatting them into the configError type,
 * and integrating with the `useConfigUserFeedback` store to display
 * or notify the user about the error.
 *
 * @returns {Function} A function `handleEntityOperationError` that accepts a
 * `ConfigApiError` object and processes it to be used with `setConfigError`
 */

export const useEntityOperationError = () => {
  const { setConfigError } = useConfigUserFeedback((state) => ({
    setConfigError: state.setConfigError,
  }));

  const handleEntityOperationError = (operationApiError: ConfigApiError) => {
    const errors = operationApiError.errors?.map((error) => ({
      detail: error.detail,
      // todo:: add path param
      // implement links from api errors https://everlong.atlassian.net/browse/CACT-1093
    }));

    const configError = {
      errors,
      type: mapStatusToErrorType(operationApiError.status),
    };

    setConfigError(configError);
  };

  return handleEntityOperationError;
};
