import React from 'react';
import { VisuallyHidden, Spinner } from '@leagueplatform/genesis-core';
import { UseBaseQueryResult } from '@tanstack/react-query';
import { useIntl } from '@leagueplatform/locales';
import type { AnnotatedJsonSchema } from '@web-config-app/core';
import {
  EntityDetailsPageLayout,
  ErrorBanner,
  ErrorPage,
} from '@web-config-app/core-react-ui';
import {
  EntityForm,
  EntityTreeView,
  useEntityFormState,
  createErrorFilteringMiddleware,
} from '@web-config-app/entity-form';
import { useConfigErrorBanner } from '@web-config-app/core-react';
import { EntityDetailsHeaderContainer } from '../../containers/entity-details-header-container/entity-details-header.container';
import {
  EntityFormSkeleton,
  SidePanelSkeleton,
} from './entity-details-skeleton.component';

export type EntityDetailsPageProps = Pick<
  UseBaseQueryResult,
  'isError' | 'isLoading'
> & {
  formData: any;
  formSchema?: AnnotatedJsonSchema;
  formPath?: string;
  entityTree: any;
  onFormDataChange: (updatedData: any) => void;
  formDataChangeDebounceLength?: number;
  handleNodeToggle?: (nodeId: string) => void;
  expandedNodes?: string[];
};

export const EntityDetailsPage: React.FC<EntityDetailsPageProps> = ({
  isLoading,
  isError: isFullPageError,
  formData,
  formSchema,
  formPath,
  entityTree,
  handleNodeToggle,
  onFormDataChange,
  expandedNodes,
  formDataChangeDebounceLength = 400,
}) => {
  const { formatMessage } = useIntl();
  const { formDataChangeHandler, localData, localSchema } = useEntityFormState({
    onFormDataChange,
    formDataChangeDebounceLength,
    formPath,
    formData,
    formSchema,
  });

  const {
    hasConfigError,
    errorBannerTitle,
    errorBannerContents,
    showFormValidation,
    filteredErrorKeywords,
  } = useConfigErrorBanner();

  if (isFullPageError) {
    return <ErrorPage />;
  }
  const entityFormMiddleware = createErrorFilteringMiddleware(
    filteredErrorKeywords,
  );

  return (
    <EntityDetailsPageLayout
      topBar={<EntityDetailsHeaderContainer isLoading={isLoading} />}
      statusBanner={
        hasConfigError && (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <ErrorBanner title={errorBannerTitle} {...errorBannerContents} />
        )
      }
      sidePanel={
        isLoading ? (
          <SidePanelSkeleton />
        ) : (
          <EntityTreeView
            entityTree={entityTree}
            onSelect={handleNodeToggle}
            activeTreeNodeId={formPath}
            expandedItems={expandedNodes}
          />
        )
      }
    >
      <>
        <VisuallyHidden>
          <Spinner
            loading={isLoading}
            loadingText={formatMessage({ id: 'LOADING' })}
          />
        </VisuallyHidden>
        {isLoading ? (
          <EntityFormSkeleton />
        ) : (
          <EntityForm
            schema={localSchema}
            data={localData}
            onDataChange={formDataChangeHandler}
            showValidation={showFormValidation}
            middleware={entityFormMiddleware}
          />
        )}
      </>
    </EntityDetailsPageLayout>
  );
};
