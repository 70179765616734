import { useEffect, useRef } from 'react';
import { useEntityDetailsProps } from '../../../hooks/use-entity-details-props/use-entity-details-props';
import { useRichTextControlArrayItemCacheKey } from './rich-text-control-array-item-provider';

/**
 * Uses the passed value in conjunction with either a precalculated cacheKey (when called within
 * a child of RichTextControlArrayItemProvider) or the current formPath to return a string key
 * we can use to pass to the RichTextEditor in order to trigger it re-initializing itself (which
 * is required to change content via props)
 */

export const useRichTextInputControlCacheKey = (value?: any) => {
  /**
   * If the rich text control is in an array and nested  it will be able to grab the two props
   * via context. `isArrayItem` will be true only when the control is nested within a
   * `RichTextControlArrayItemProvider`
   */
  const { cacheKey: arrayItemCacheKey, isArrayItem } =
    useRichTextControlArrayItemCacheKey();
  const { formPath } = useEntityDetailsProps();
  const cacheKeyRef = useRef<string | undefined>(arrayItemCacheKey ?? formPath);
  const valueRef = useRef<string | undefined>(value);

  useEffect(() => {
    if (
      isArrayItem &&
      (!cacheKeyRef.current || cacheKeyRef.current !== arrayItemCacheKey)
    ) {
      /**
       * If the rich text is in an array then its cacheKey is managed externally and
       * only needs to be updated if the key returned from `useRichTextControlArrayItemCacheKey`
       * changes.
       */
      cacheKeyRef.current = arrayItemCacheKey;
    } else if (cacheKeyRef.current !== formPath && valueRef.current !== value) {
      /**
       * The tip tap editor is pretty finicky when it comes to updating content. We need some mechanism
       * to construct a key that tells `useEditor` when it needs to create a new instance containing the current
       * `value`
       *
       * Since the value and editorCacheKey get updated on separate render cycles in the config app, we want to
       * retain the reference to them until BOTH of the values have been updated. Updating the `cacheKeyRef.current`
       * will trigger the destroy/create cycle and return a NEW editorConfig
       */

      cacheKeyRef.current = formPath;
      valueRef.current = value;
    } else {
      /**
       * Otherwise we need to keep the valueRef.current up to date with any changes to avoid
       * the above check failing when value has been updated and a user switches to a new form page that
       * also contains a rich text property.
       */
      valueRef.current = value;
    }
  }, [formPath, value, arrayItemCacheKey, isArrayItem]);

  return cacheKeyRef.current;
};
