import type { AnnotatedJsonSchema } from '@web-config-app/core';
import { getSchemaFromEntries } from '../get-schema-from-entries/get-schema-from-entries';

/**
 * Alters a schema of type `object` by only including properties for which the passed
 * `filterProperties` function returns true.
 *
 * The `required` array for the schema will also be updated with any previously
 * required properties that were removed from the schema also being removed from
 * its required properties array.
 *
 * TODODODODODODOD
 */

export interface GetSchemaWithMutatedPropertiesArgs {
  schema: AnnotatedJsonSchema;
  matchProperties: (propertySchema: AnnotatedJsonSchema) => boolean;
  mutateProperties: (
    schema: Partial<AnnotatedJsonSchema>,
  ) => AnnotatedJsonSchema;
}
export const getSchemaWithMutatedProperties = ({
  schema,
  matchProperties,
  mutateProperties,
}: GetSchemaWithMutatedPropertiesArgs) => {
  if (schema.type !== 'object') {
    return schema;
  }

  const schemaEntries = Object.entries(schema.properties ?? {});
  const recalculatedProperties = getSchemaFromEntries(
    schemaEntries.map(([property, propertySchema]) => {
      const mutatedSchema = matchProperties(propertySchema)
        ? mutateProperties(propertySchema)
        : propertySchema;
      return [property, mutatedSchema] as [string, AnnotatedJsonSchema];
    }),
  );

  return {
    ...schema,
    properties: recalculatedProperties,
    /**
     * Need to remove any properties that were in the schema's `required` array that we are removing
     * from `properties`
     */
    ...(schema.required && {
      required: schema.required.filter((property: string) =>
        Object.keys(recalculatedProperties).includes(property),
      ),
    }),
  } as AnnotatedJsonSchema;
};
