import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { EntityDetailsHeader } from '@web-config-app/core-react-ui';
import {
  useConfigAppParams,
  useConfigAppPath,
  useEntity,
} from '@web-config-app/core-react';
import {
  useEntityDetailsProps,
  useEntityWorkflowActions,
  useEntityHeaderStatus,
  StatusIconMatch,
  EntityMenuItem,
} from '@web-config-app/entity-form';
import {
  getEntityStatusBadgeProps,
  EntityDetailAction,
  EntityStatusValue,
} from '@web-config-app/core';

export const EntityDetailsHeaderContainer = ({
  isLoading = false,
}: {
  isLoading: boolean;
}) => {
  const { formatMessage } = useIntl();
  const { entityListPath } = useConfigAppPath();
  const { domainId, entityId } = useConfigAppParams<{
    domainId: string;
    entityId: string;
  }>();

  const { nameTranslationKey } = useEntity({
    domainId,
    entityId,
  });

  const entityConfigName = formatMessage(
    { id: nameTranslationKey },
    { count: 1 },
  );

  const { operations, name, status, instanceId, entityRootData } =
    useEntityDetailsProps();
  const { isEntitySaved, messageKey } = useEntityHeaderStatus({
    operations,
    entityRootData,
    status,
  });
  const { mainWorkflowActions, menuItemWorkflowActions } =
    useEntityWorkflowActions({
      operations,
      status,
      isEntitySaved,
      entityConfigName,
    });
  const statusBadgeProps = getEntityStatusBadgeProps(status);

  // No badge for new entities
  const statusBadge = instanceId
    ? {
        label: formatMessage({ id: statusBadgeProps?.labelKey }),
        status: statusBadgeProps?.genesisStatus,
      }
    : undefined;

  const create = mainWorkflowActions.find(
    (action) => action.type === EntityDetailAction.Create,
  );
  const update = mainWorkflowActions.find(
    (action) => action.type === EntityDetailAction.Update,
  );
  const publish = mainWorkflowActions.find(
    (action) => action.type === EntityDetailAction.Publish,
  );
  const republish = mainWorkflowActions.find(
    (action) => action.type === EntityDetailAction.Republish,
  );
  const unarchive = mainWorkflowActions.find(
    (action) => action.type === EntityDetailAction.Unarchive,
  );

  const isArchived = status === EntityStatusValue.Archived;

  const getSecondaryAction = () => {
    if (isArchived) {
      return unarchive;
    }
    if (instanceId) {
      return update;
    }
    return create;
  };

  const secondaryAction = getSecondaryAction();
  const primaryAction =
    status === EntityStatusValue.Draft || !instanceId ? publish : republish;

  const menuItems = menuItemWorkflowActions
    .filter((item) => item?.display)
    .map((item) => ({
      id: item?.type as EntityDetailAction,
      label: (
        <EntityMenuItem
          item={item}
          entityConfigName={entityConfigName}
          entityListPath={entityListPath}
        />
      ),
    }));

  const onMenuItemClicked = React.useCallback(
    (itemId: EntityDetailAction) =>
      menuItemWorkflowActions
        .find((action) => action.type === itemId)
        ?.action?.(),
    [menuItemWorkflowActions],
  );

  const menuItemGroup = { menuItems, onMenuItemClicked };

  const entityDetailSaveStatus = isArchived ? undefined : (
    <StatusIconMatch showIcon={isEntitySaved} messageKey={messageKey} />
  );

  const entityInstanceName =
    name || `${formatMessage({ id: 'UNTITLED' })} ${entityConfigName}`;

  return (
    <EntityDetailsHeader
      isLoading={isLoading}
      exitModalPrimaryButtonPath={entityListPath}
      menuItemGroup={menuItemGroup}
      secondaryAction={secondaryAction}
      primaryAction={primaryAction}
      statusBadge={statusBadge}
      entityInstanceName={entityInstanceName}
      entityDetailSaveStatus={entityDetailSaveStatus}
      entityConfigName={entityConfigName}
      isEntitySaved={isEntitySaved}
      instanceId={instanceId}
    />
  );
};
