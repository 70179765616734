import React from 'react';
import { Spinner, Modal, StackLayout } from '@leagueplatform/genesis-core';

// modal width according to designs
const MODAL_CONTENT_WIDTH = '26rem';

export interface LoadingOverlayModalProps {
  modalTrigger: React.ReactNode;
  isOpen: boolean;
  loadingText: string;
}

export const LoadingOverlayModal: React.FC<
  React.PropsWithChildren<LoadingOverlayModalProps>
> = ({ modalTrigger, isOpen, loadingText, children }) => (
  <Modal.Root open={isOpen} onOpenChange={() => !isOpen}>
    <Modal.Trigger>{modalTrigger}</Modal.Trigger>
    <Modal.Content showCloseButton={false} width={MODAL_CONTENT_WIDTH}>
      <StackLayout
        spacing="$two"
        horizontalAlignment="center"
        verticalAlignment="center"
      >
        <Modal.Title>
          <Spinner loading loadingText={loadingText} size="$four" />
        </Modal.Title>
        <Modal.Description>{children}</Modal.Description>
      </StackLayout>
    </Modal.Content>
  </Modal.Root>
);
