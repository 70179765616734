import * as React from 'react';
import { JsonEditor, JsonEditorValue } from '@web-config-app/core-react-ui';
import { useRulesTemplates } from '@web-config-app/core-react';
import type { AnnotatedJsonSchema } from '@web-config-app/core';
import {
  InputHint,
  InputLabel,
  InputStatusMessage,
  ParagraphText,
  StackItem,
  StackLayout,
  StatusBanner,
} from '@leagueplatform/genesis-core';
import { ControlProps } from '@jsonforms/core';
import type { ControlComponent } from '../../../types/controls';
import { EntityFormControl } from '../../entity-form-control/entity-form-control.component';
import { JsonLogicInputTopBar } from './json-logic-input-top-bar.component';
import { useEntityDetailsProps } from '../../../hooks/use-entity-details-props/use-entity-details-props';
import { useEntityFormControl } from '../../../hooks/use-entity-form-control/use-entity-form-control';
import { decodeJsonLogicString } from './decode-json-logic-string';
/*
 * JsonLogicInputControl needs its own wrapper because it must bind the label and editor together with the correct id - the default wrapper uses Genesis form field and through that the input element has to be a direct child
 *
 */

const JsonLogicInputControlWrapper: React.FC<
  React.PropsWithChildren<ControlProps>
> = ({ children, ...props }) => {
  const entityFormControlProps = useEntityFormControl(props);
  const { banner, label, required, inputStatus, statusMessage, hint, id } =
    entityFormControlProps;
  const showBanner = banner?.title || banner?.description;
  return (
    <StackLayout css={{ marginTop: '$oneAndHalf' }}>
      {showBanner && (
        <StatusBanner
          status={banner.status}
          title={banner.title}
          css={{ width: '100%', marginY: '$half' }}
        >
          {banner.description && (
            <ParagraphText>{banner.description}</ParagraphText>
          )}
        </StatusBanner>
      )}
      <StackItem css={{ width: '100%', marginBottom: '$half' }}>
        <InputLabel htmlFor={id} required={required}>
          {label}
        </InputLabel>
        <InputHint>{hint}</InputHint>
      </StackItem>
      {children}
      <InputStatusMessage
        inputStatus={inputStatus}
        statusIconLabel={inputStatus}
      >
        {statusMessage}
      </InputStatusMessage>
    </StackLayout>
  );
};

const getRulesTemplateType = (schema: AnnotatedJsonSchema) =>
  schema?.['x-entity-presentation']?.rulesTemplateType;

const JsonLogicInputControlContents = ({
  data,
  handleChange,
  path,
  enabled,
  id,
  schema,
}: {
  data: string;
  handleChange: (path: string, value: any) => void;
  path: string;
  enabled: boolean;
  id: string;
  schema: AnnotatedJsonSchema;
}) => {
  const { entityType, formPath } = useEntityDetailsProps();
  const rulesType = getRulesTemplateType(schema);

  /**
   * The two refs are needed to handle a bug where if switching between 2 different json-logic controls on separate pages directly, stale data (from the previous json-logic component) was being shown in the control.
   */
  const cacheKeyRef = React.useRef<string | undefined>(formPath);
  const valueRef = React.useRef<string | undefined>(data);

  const rulesTemplates = useRulesTemplates({ entityType, rulesType });

  const [jsonContent, setJsonContent] = React.useState<JsonEditorValue>({
    json: undefined,
    text: decodeJsonLogicString(data),
  });

  React.useEffect(() => {
    /**
     * Handles a bug where the component initially renders with data `undefined` before being
     * eventually passed as the existing string data and where if switching between 2 different json-logic controls on separate pages directly, stale data (from the previous json-logic component) was being shown in the control.. This can likely be mitigated by future state optimization work.
     *
     * TODO: https://everlong.atlassian.net/browse/CACT-1290
     */
    if (
      (typeof data === 'string' && typeof jsonContent.text === 'undefined') ||
      (cacheKeyRef.current !== formPath && valueRef.current !== data)
    ) {
      cacheKeyRef.current = formPath;
      valueRef.current = data;
      setJsonContent({
        json: undefined,
        text: decodeJsonLogicString(data),
      });
    } else {
      valueRef.current = data;
    }
  }, [data, formPath, jsonContent.text]);

  const handleOnJsonChange = (content: JsonEditorValue) => {
    setJsonContent(content);
    handleChange(path, content.text);
  };

  const [selectedRuleTemplate, setSelectedRuleTemplate] = React.useState<
    string | undefined
  >();

  const handleTemplateChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const templateValue = event.target.value;
    setSelectedRuleTemplate(templateValue);
  };

  const handleOnApply = () => {
    const selectedTemplate = rulesTemplates?.find(
      (template) => template.id === selectedRuleTemplate,
    );

    handleOnJsonChange({
      json: undefined,
      text: JSON.stringify(selectedTemplate?.rules ?? {}, null, 2),
    });
  };

  const showTemplateSelect = enabled && rulesTemplates;

  return (
    <JsonEditor
      readOnly={!enabled}
      onChange={handleOnJsonChange}
      editorValue={jsonContent}
      editorId={id}
      topBarContents={
        showTemplateSelect && (
          <JsonLogicInputTopBar
            rulesTemplates={rulesTemplates}
            onChange={handleTemplateChange}
            selectedTemplate={selectedRuleTemplate}
            onClickApply={handleOnApply}
          />
        )
      }
    />
  );
};

// Main JsonLogicInputControl component
export const JsonLogicInputControl: ControlComponent = (props) => (
  <EntityFormControl
    {...props}
    renderControl={JsonLogicInputControlContents}
    wrapperComponent={JsonLogicInputControlWrapper}
  />
);
