import * as React from 'react';
import type {
  Entity,
  EntityDetail,
  EntityDetailSchema,
} from '@web-config-app/core';
import {
  useEntityOperations,
  UseEntityOperationsProps,
  useEntityDetailsData,
  useIncludedEntities,
} from '@web-config-app/core-react';
import {
  EntityDetailsProvider,
  createDefaultValueForSchema,
  useSyncDataSources,
} from '@web-config-app/entity-form';
import {
  computeSchema,
  applyDefaultSchemaAnnotations,
} from '@web-config-app/schema-utils';

export interface EntityDetailsPageContainerProps {
  entity: Entity;
  instanceId: string;
  path: string;
  options?: UseEntityOperationsProps['options'];
  createDefaultEntityData?: boolean;
}

export const EntityDetailsPageContainer = ({
  entity,
  instanceId,
  options,
  path,
  children,
  createDefaultEntityData,
}: React.PropsWithChildren<EntityDetailsPageContainerProps>) => {
  const defaultSchema = React.useMemo(
    () => applyDefaultSchemaAnnotations(entity.schema),
    [entity.schema],
  );
  const defaultEntityData = React.useMemo(
    () =>
      createDefaultEntityData
        ? createDefaultValueForSchema<EntityDetail>(defaultSchema)
        : undefined,
    [defaultSchema, createDefaultEntityData],
  );

  const { entityRootData, setEntityRootData } = useEntityDetailsData({
    data: defaultEntityData,
  });

  const rootSchema = React.useMemo(
    () => computeSchema(defaultSchema, entityRootData) as EntityDetailSchema,
    [defaultSchema, entityRootData],
  );

  const included = useIncludedEntities();

  useSyncDataSources(rootSchema, entityRootData, included.includedEntities);

  const operations = useEntityOperations({
    entity,
    instanceId,
    options,
    entityRootData,
    onEntityGetSuccess: (data, includedEntities) => {
      setEntityRootData(data);
      included.setIncludedEntities(includedEntities);
    },
  });

  return (
    <EntityDetailsProvider
      entityRootData={entityRootData}
      setEntityRootData={setEntityRootData}
      operations={operations}
      rootSchema={rootSchema}
      path={path}
      instanceId={instanceId}
      entityType={entity.type}
      entity={entity}
      included={included}
    >
      {children}
    </EntityDetailsProvider>
  );
};
