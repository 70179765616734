import {
  AnnotatedJsonSchema,
  SchemaTransformerArgs,
  SchemaTransformer,
} from '@web-config-app/core';
import { getSchemaWithFilteredProperties } from '../../get-schema-with-filtered-properties/get-schema-with-filtered-properties';
import { evaluateIncludeIfCondition } from '../../evaluate-include-if-conditional/evaluate-include-if-conditional';

export const applyIncludeIfConditional: SchemaTransformer = ({
  schema,
  data,
}: SchemaTransformerArgs) =>
  getSchemaWithFilteredProperties(
    schema,
    ([, propertySchema]: [string, AnnotatedJsonSchema]) =>
      evaluateIncludeIfCondition({ schema: propertySchema, data }),
  );
