import React, { FC } from 'react';
import {
  HeadingBar,
  Divider,
  StackLayout,
  Button,
  Box,
  SkeletonText,
  SkeletonRectangle,
  GDSStatus,
  focusOutlineInner,
  GDSStyleObject,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { Link } from '@leagueplatform/routing';
import { EntityDetailAction, WorkflowAction } from '@web-config-app/core';
import { EntityDetailsRightHeaderActions } from '../entity-details-right-header-actions/entity-details-right-header-actions.component';
import { EntityNameAndStatus } from '../entity-name-and-status/entity-name-and-status.component';
import { EntityDetailsConfirmationModal } from '../entity-details-confirmation-modal/entity-details-confirmation-modal.component';
import { LoadingOverlayModal } from '../loading-overlay-modal/loading-overlay-modal.component';

export interface EntityDetailsHeaderProps {
  menuItemGroup: {
    menuItems: {
      id: EntityDetailAction;
      label: React.ReactNode;
    }[];
    onMenuItemClicked: (itemId: EntityDetailAction) => void;
  };
  isLoading: boolean;
  secondaryAction?: WorkflowAction;
  primaryAction?: WorkflowAction;
  statusBadge?: {
    label: string;
    status: GDSStatus;
  };
  entityInstanceName?: string;
  entityDetailSaveStatus?: JSX.Element;
  entityConfigName: string;
  exitModalPrimaryButtonPath: string;
  isEntitySaved: boolean;
  instanceId?: string;
}

const commonButtonCssStyles: GDSStyleObject = {
  padding: '$oneAndHalf',
  border: 0,
  '&[class*="GDS"]:focus': {
    outlineOffset: 0,
    outline: 0,
  },
  '&:focus': {
    ...focusOutlineInner,
  },
};

const EntityDetailsHeaderTriggerLink = ({
  entityListPath,
}: {
  entityListPath: string;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Button
      icon="tinyClose"
      hideLabel
      priority="tertiary"
      quiet
      size="toolbar"
      as={Link}
      to={entityListPath}
      css={{ ...commonButtonCssStyles }}
    >
      {formatMessage({ id: 'EXIT' })}
    </Button>
  );
};

export const EntityDetailsHeaderSkeleton = () => (
  <HeadingBar
    css={{
      width: '100%',
    }}
    leftAction={
      <Box css={{ minWidth: 300 }}>
        <SkeletonText header />
      </Box>
    }
    rightAction={
      <StackLayout
        orientation="horizontal"
        spacing="$one"
        verticalAlignment="center"
      >
        <SkeletonText width={100} />
        <SkeletonRectangle height={38} width={120} />
        <SkeletonRectangle height={38} />
      </StackLayout>
    }
  />
);

export const EntityDetailsHeader: FC<EntityDetailsHeaderProps> = ({
  menuItemGroup,
  secondaryAction,
  primaryAction,
  statusBadge,
  exitModalPrimaryButtonPath,
  isLoading = false,
  entityInstanceName,
  entityDetailSaveStatus,
  entityConfigName,
  isEntitySaved,
  instanceId,
}) => {
  const { formatMessage } = useIntl();
  const exitModalTitle = formatMessage(
    { id: 'EXIT_ENTITY_DETAIL_TITLE' },
    { name: entityConfigName },
  );
  const exitModalDescription = formatMessage({
    id: 'EXIT_ENTITY_DETAIL_DESCRIPTION',
  });

  const primaryButtonText = formatMessage({
    id: 'EXIT_ENTITY_DETAIL_PRIMARY_CTA',
  });
  return (
    <StackLayout
      orientation="horizontal"
      css={{ alignItems: 'center', height: '100%' }}
    >
      {!isEntitySaved ? (
        <EntityDetailsConfirmationModal
          modalTrigger={
            <Button
              icon="tinyClose"
              hideLabel
              priority="tertiary"
              quiet
              size="toolbar"
              css={{ ...commonButtonCssStyles }}
            >
              {formatMessage({ id: 'EXIT' })}
            </Button>
          }
          title={exitModalTitle}
          description={exitModalDescription}
          primaryButtonText={primaryButtonText}
          primaryButtonPath={exitModalPrimaryButtonPath}
        />
      ) : (
        <EntityDetailsHeaderTriggerLink
          entityListPath={exitModalPrimaryButtonPath}
        />
      )}
      <Divider orientation="vertical" css={{ margin: 0 }} />
      {isLoading ? (
        <EntityDetailsHeaderSkeleton />
      ) : (
        <HeadingBar
          css={{
            width: '100%',
            '.GDS-heading-bar-base': {
              paddingX: '$oneAndQuarter',
              gap: '$one',
            },
          }}
          leftAction={
            <EntityNameAndStatus
              entityInstanceName={entityInstanceName}
              statusBadgeLabel={statusBadge?.label}
              statusBadgeStatus={statusBadge?.status}
              instanceId={instanceId}
            />
          }
          rightAction={
            <EntityDetailsRightHeaderActions
              menuItemGroup={menuItemGroup}
              entityDetailSaveStatus={entityDetailSaveStatus}
              primaryAction={
                primaryAction?.display && (
                  <LoadingOverlayModal
                    modalTrigger={
                      <Button
                        priority="primary"
                        size="medium"
                        disabled={primaryAction?.disabled}
                        loading={primaryAction?.actionStatus === 'pending'}
                        onClick={() => {
                          primaryAction?.action?.();
                        }}
                      >
                        {primaryAction?.label}
                      </Button>
                    }
                    isOpen={primaryAction?.actionStatus === 'pending'}
                    loadingText={`${primaryAction?.label} ${entityConfigName}`}
                  >
                    <ParagraphText>{`${primaryAction?.label} ${entityConfigName}...`}</ParagraphText>
                  </LoadingOverlayModal>
                )
              }
              secondaryAction={
                secondaryAction?.display && (
                  <LoadingOverlayModal
                    modalTrigger={
                      <Button
                        priority="secondary"
                        size="medium"
                        onClick={() => {
                          secondaryAction?.action?.();
                        }}
                        disabled={secondaryAction?.disabled}
                        loading={secondaryAction?.actionStatus === 'pending'}
                      >
                        {secondaryAction?.label}
                      </Button>
                    }
                    isOpen={secondaryAction?.actionStatus === 'pending'}
                    loadingText={`${secondaryAction?.label} ${entityConfigName}`}
                  >
                    <ParagraphText>{`${secondaryAction?.label} ${entityConfigName}...`}</ParagraphText>
                  </LoadingOverlayModal>
                )
              }
            />
          }
        />
      )}
    </StackLayout>
  );
};
