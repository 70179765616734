import * as React from 'react';
import { Tooltip, ParagraphText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  WorkflowActionModal,
  MenuItemWorkflowAction,
} from '@web-config-app/core';
import {
  EntityDetailsConfirmationModal,
  LoadingOverlayModal,
} from '@web-config-app/core-react-ui';
import { EntityMenuItemText } from '../entity-menu-item-text/entity-menu-item-text.component';

interface EntityMenuItemProps {
  item: MenuItemWorkflowAction;
  entityConfigName?: string;
  entityListPath: string;
}

export const EntityMenuItem: React.FC<EntityMenuItemProps> = ({
  item,
  entityConfigName,
  entityListPath,
}) => {
  const { formatMessage } = useIntl();
  const { disabled, label, tooltip, modalType, actionStatus } = item;
  if (modalType === WorkflowActionModal.confirmation) {
    return (
      <EntityDetailsConfirmationModal
        modalTrigger={
          <EntityMenuItemText
            disabled={disabled}
            label={label}
            color="$onSurfaceTextCritical"
          />
        }
        title={formatMessage(
          { id: 'EXIT_ENTITY_DETAIL_TITLE' },
          { name: entityConfigName },
        )}
        description={formatMessage({
          id: 'EXIT_ENTITY_DETAIL_DESCRIPTION',
        })}
        primaryButtonText={formatMessage({
          id: 'EXIT_ENTITY_DETAIL_PRIMARY_CTA',
        })}
        primaryButtonPath={entityListPath}
      />
    );
  }

  if (modalType === WorkflowActionModal.pending) {
    return (
      <LoadingOverlayModal
        modalTrigger={<EntityMenuItemText disabled={disabled} label={label} />}
        isOpen={actionStatus === 'pending'}
        loadingText={`${label} ${entityConfigName}`}
      >
        <ParagraphText>{`${label} ${entityConfigName}...`}</ParagraphText>
      </LoadingOverlayModal>
    );
  }

  return disabled && tooltip ? (
    <Tooltip
      align="center"
      avoidCollisions
      content={tooltip}
      side="left"
      sideOffset={30}
    >
      <EntityMenuItemText disabled={disabled} label={label} />
    </Tooltip>
  ) : (
    <EntityMenuItemText disabled={disabled} label={label} />
  );
};
