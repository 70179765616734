import type { AnnotatedJsonSchema } from '@web-config-app/core';
import { getSchemaFromEntries } from '../get-schema-from-entries/get-schema-from-entries';

/**
 * Alters a schema of type `object` by only including properties for which the passed
 * `filterProperties` function returns true.
 *
 * The `required` array for the schema will also be updated with any previously
 * required properties that were removed from the schema also being removed from
 * its required properties array.
 */

export const getSchemaWithFilteredProperties = (
  schema: AnnotatedJsonSchema,
  filterProperties: (
    schemaEntries: [string, AnnotatedJsonSchema],
  ) => boolean = () => true,
) => {
  if (schema.type !== 'object') {
    return schema;
  }

  const recalculatedProperties = getSchemaFromEntries(
    Object.entries(schema.properties ?? {}).filter(filterProperties),
  );

  return {
    ...schema,
    properties: recalculatedProperties,
    /**
     * Need to remove any properties that were in the schema's `required` array that we are removing
     * from `properties`x
     */
    ...(schema.required && {
      required: schema.required.filter((property: string) =>
        Object.keys(recalculatedProperties).includes(property),
      ),
    }),
  } as AnnotatedJsonSchema;
};
